<template>
    <SmartLinkItemComponent custom-tag="div" class="split-content-block-publication-list" v-if="model"
        :id="`_${model.system.id}`" :component="!model.system.workflow">
        <PublicationItem v-for="item in source" :model="item" />
        <!-- Mobile Button - Hidden on Desktop -->
        <div class="d-block d-lg-none">
            <CallToAction custom-class="btn btn-primary btn-sm" v-for="cta in data.ctas" :model="cta" />
        </div>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { type CallToActionModel, type PublicationItemModel, type PublicationListModel, type SplitContentBlockPublicationListModel } from '~/models';

const props = defineProps<{ model: SplitContentBlockPublicationListModel }>();
const pageStore = usePageStore();
const data = computed(() => {
    return {
        numberOfItens: props.model.elements.numberOfItemsToDisplay.value,
        ctas: pageStore.getListAs<CallToActionModel>(props.model.elements.mobileCallToAction.value)
    }
});

const sortModel = ref(0);
const source: Ref<PublicationItemModel[]> = ref([]);

const lastAddIndex = ref(1);
const response = await useFetch('/api/publication-list');

const result = response?.data?.value?.data?.item.sort((a, b) => getDateAsNumber(b.elements.publicationDate.value) - getDateAsNumber(a.elements.publicationDate.value));

loadData();
function loadData() {
    const limit = lastAddIndex.value * (data.value.numberOfItens ?? 3)
    if (result) {

        let increaseIdx = false;
        result.forEach((publication, idx) => {
            if (idx < limit && !source.value.includes(publication)) {
                increaseIdx = true;
                source.value.push(publication);
            }
        });

        if (increaseIdx) {
            lastAddIndex.value++;
        }
    }
}

function getDateAsNumber(str?: string | null) {
    if (!str) return 0;

    const date = new Date(str);
    return parseInt(`${date.getFullYear()}${date.getMonth()}${date.getDate()}`)
}
</script>